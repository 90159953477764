/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:493acf40-b5be-4b18-83cc-cf260619103c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jeSvng4qK",
    "aws_user_pools_web_client_id": "cbt78hcrtlukscceepgs61qdj",
    "sso_auth_key": "Zt66ZTNbEJ6MCR6fTRquS8Y9JqoN4YMEap3T4akv",
    "oauth": {},
    "aws_content_delivery_bucket": "tillpayments-prd-us-east-1-website",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://tillpayments-prd.merchant-dispute.com"
};


export default awsmobile;
